<template>
  <div class="quote-fi">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="isEditMode"
      class="quote-fi__badge badge-fi"
      v-html="fields.tag.editable"
    />
    <div
      v-if="!isEditMode && fields.tag?.value"
      class="quote-fi__badge badge-fi"
      v-html="fields.tag.value"
    />

    <div class="quote-fi__quote">
      <q>
        <span
          v-if="isEditMode"
          v-html="fields.quote.editable"
        />
        <span
          v-else
          v-html="fields.quote?.value"
        />
      </q>
    </div>

    <div
      v-if="isEditMode"
      class="quote-fi__author"
      v-html="fields.author.editable"
    />

    <div
      v-if="!isEditMode && fields.author?.value"
      class="quote-fi__author"
      v-html="fields.author.value"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
export default {
  name: 'QuoteFi',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@import './quote-fi';
</style>

<style lang="scss">
.quote-fi {
  &__quote {
    span.scWebEditInput {
      display: inline;
    }
  }
}
</style>
